<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ສະຫຼຸບຍອດຂາຍ</h2></v-col>
      <!--  <v-col class="al-end"> <v-btn color="primary">Export</v-btn></v-col> -->
    </v-row>
    <!-- <v-row class="my-2" justify="end">
      <v-col><h2>ທັງໝົດ {{}}</h2></v-col>
    </v-row> -->
    <!-- <hr /> -->

    <v-row>
      <v-col cols>
        <v-menu
            v-model="start_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="start_date"
                label="ເລີ່ມວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="start_date"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols>
        <v-menu
            v-model="end_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="end_date"
                label="ຫາວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="end_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex" cols>
        <v-select
            v-model="selectedPayment"
            :items="payment_Types"
            label="ປະເພດການຊຳລະ"
            item-text="name"
            item-value="value"
            dense
            outlined
        ></v-select>
      </v-col>
      <!--
      <v-col class="d-flex" cols="3">
        <v-select
          v-model="selectedUser"
          :items="users"
          label="User"
          item-text="name"
          item-value="id"
          dense
          outlined
          @input="fetchBranchInvoice()"
        ></v-select>
      </v-col>
  -->
    </v-row>

    <v-row class="mb-1">
      <v-col>
        <h4>ລວມບິນ: {{ Intl.NumberFormat().format(totalBill) }}</h4>
      </v-col>
      <v-col>
        <h4>ລວມຕຸກນ້ຳ: {{ Intl.NumberFormat().format(sumTotalBottles) }}</h4>
      </v-col>
      <v-col>
        <h4>ລວມເງິນທັງໝົດ: {{ Intl.NumberFormat().format(sumTotal) }}</h4>
      </v-col>
      <v-col>
        <h4>ລວມເງິນຂາຍນ້ຳ: {{ Intl.NumberFormat().format(sumTotalPrice) }}</h4>
      </v-col>
      <v-col>
        <h4 class="primary--text">
          ເງິນສົດ: {{ Intl.NumberFormat().format(sumTotalCash) }}
        </h4>
      </v-col>
      <!--
      <v-col>
        <h4 class="warning--text">
          ຄ່າມັດຈຳ: {{ Intl.NumberFormat().format(totalBondingFee()) }}
        </h4>
      </v-col>
-->
    </v-row>
    <v-row class="mb-1">
      <v-col>
        <h4 class="success--text">
          ທະນາຄານ: {{ Intl.NumberFormat().format(sumTotalBank) }}
        </h4>
      </v-col>
      <v-col>
        <h4 class="info--text">
          ສະໜັບສະໜຸນ: {{ Intl.NumberFormat().format(sumTotalSupport) }}
        </h4>
      </v-col>
      <v-col>
        <h4 class="error--text">
          ຕິດໜີ້: {{ Intl.NumberFormat().format(sumTotalDebt) }}
        </h4>
      </v-col>
      <v-col>
        <h4>ລວມສ່ວນຫຼຸດ: {{ Intl.NumberFormat().format(sumTotalDiscount) }}</h4>
      </v-col>
      <v-col>
        <h4>ລວມມັດຈຳ: {{ Intl.NumberFormat().format(sumTotalBondingFees) }}</h4>
      </v-col>
    </v-row>
    <!-- <hr /> -->
    <v-data-table
        :headers="headers"
        :items="branchInvoices"
        :disable-pagination="true"
        hide-default-footer
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
    >
      <template v-slot:item.total="{ item }">
        {{ Intl.NumberFormat().format(item.subTotal + item.bonding_fees - item.discount) }}
      </template>
      <template v-slot:item.bonding_fees="{ item }">
        {{ Intl.NumberFormat().format(item.bonding_fees) }}
      </template>
      <template v-slot:item.discount="{ item }">
        {{ Intl.NumberFormat().format(item.discount) }}
      </template>
      <template v-slot:item.subTotal="{ item }">
        {{ Intl.NumberFormat().format(item.subTotal) }}
      </template>
      <template v-slot:item.team="{ item }">
        <div class="primary-color">{{ item.createBy.name }}</div>
        <span v-for="(member, index) in item.createByMembers" :key="index">
          <span>{{ member.name }}, </span>
        </span>
      </template>
      <!-- <template v-slot:item.invoice_status="{ item }">
        <v-chip color="success">{{ item.invoice_status }}</v-chip>
      </template> -->
      <template v-slot:item.payment_type="{ item }">
        <div :class="getPaymentTypeColor(item.payment_type)">
          {{ paymentType(item.payment_type) }}
        </div>
      </template>
      <!--Action -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="viewInvoice(item.id)"
        >mdi-eye
        </v-icon
        >
        <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
    </v-data-table>
    <br/>
    <template>
      <Pagination
          v-if="pagination.last_page > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchBranchInvoice()"
      ></Pagination>
    </template>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      start_menu: false,
      end_menu: false,

      loading: false,
      bankLoading: false,
      TableLoading: false,
      viewModalBranch: false,
      invoiceId: "",
      branchInvoices: [],
      invoiceDetailItem: [],
      invoiceDetail: {},
      customerDetail: {},
      createdByDetail: {},
      branches: [],
      selectedBranch: "all",
      search: "",
      type: "branch",
      users: [],
      selectedUser: "all",
      //Sum Variable
      totalBill: 0,
      sumTotalPrice: 0,
      sumTotalCash: 0,
      sumTotalDebt: 0,
      sumTotalBank: 0,
      sumTotalSupport: 0,
      sumTotalBottles: 0,
      sumTotal: 0,
      sumTotalBondingFees: 0,
      sumTotalDiscount: 0,
      //Payment Type
      selectedPayment: "all",
      //Branch default value
      defaultBranchValue: {
        id: "all",
        name: "ທັງໝົດ",
      },
      payment_Types: [
        {
          value: "all",
          name: "ທັງໝົດ",
        },
        {
          value: "cash",
          name: "ເງິນສົດ",
        },
        {
          value: "bank",
          name: "ທະນາຄານ",
        },
        {
          value: "support",
          name: "ສະໜັບສະໜູນ",
        },
        {
          value: "debt",
          name: "ຕິດໜີ້",
        },
      ],
      userProfile:{},
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 70,
      headers: [
        {
          text: "ເລກບິນ",
          align: "start",
          sortable: false,
          value: "invoice_number",
          width: "100"
        },
        {
          text: "ລະຫັດລູກຄ້າ",
          align: "start",
          sortable: false,
          value: "customer.customer_code",
          width: "150"
        },
        {text: "ຈຳນວນ", align: "center", value: "totalBottle", width: "100"},
        // { text: "ຜູ້ຂາຍ", value: "createBy.name" },
        {text: "ລູກຄ້າ", value: "customer.name", sortable: false, width: "150"},
        {text: "ມັດຈຳ", value: "bonding_fees", width: "100"},
        {text: "ສ່ວນຫຼຸດ", value: "discount", width: "120"},
        {text: "ຄ່ານ້ຳ", value: "subTotal", width: "120"},
        {text: "ລວມເງິນ", value: "total", width: "120"},
        {text: "ຊຳລະ", value: "payment_type", sortable: false},
        {text: "ວັນທີຂາຍ", value: "createdDate", width: "120"},
        {text: "ວັນທີຊຳລະ", value: "paymentDate", width: "120"},
        {text: "ທີມຂາຍ", value: "team", align: "start", sortable: false, width: "150"},
        {text: "", value: "actions", sortable: false, align: "center"},
      ],
      editedIndex: -1,
    };
  },
  metaInfo() {
    return {
      title: `ສະຫຼຸບຍອດຂາຍ - ທັງໝົດ`,
      meta: [
        {name: 'description', content: 'ສະຫຼຸບຍອດຂາຍ - ທັງໝົດ'},
      ]
    }
  },

  methods: {
    fetchBranchInvoice() {
      this.TableLoading = true;
      this.$axios
          .post("report/sales/by/branch", {
            branchId: this.profile.id,
            payment_type: this.selectedPayment,
            startDate: this.start_date,
            endDate: this.end_date,
            page: this.pagination.current_page,
            per_page: this.per_page,
          })
          .then((res) => {
            setTimeout(() => {
              this.branchInvoices = res.data.invoices.data;
              this.pagination = res.data.invoices;
              this.totalBill = res.data.totalBill;
              this.sumTotalPrice = res.data.sumTotalPrice;
              // this.sumTotalDebt = res.data.sumTotalDebt;
              // this.sumTotalBank = res.data.sumTotalBank;
              // this.sumTotalSupport = res.data.sumTotalSupport;
              this.sumTotalBottles = res.data.sumTotalBottles;
              this.sumTotal = res.data.sumTotal;
              if (this.selectedPayment == "all") {
                this.sumTotalCash =
                    res.data.sumTotalCash;
                this.sumTotalBank =
                    res.data.sumTotalBank;
                this.sumTotalDebt =
                    res.data.sumTotalDebt;
                this.sumTotalSupport =
                    res.data.sumTotalSupport;
                this.sumTotalBondingFees = res.data.SumTotalBondingFees;
                this.sumTotalDiscount = res.data.sumTotalDiscount;
              } else if (this.selectedPayment == "cash") {
                this.sumTotalCash = res.data.sumTotalCash;
                this.sumTotalBondingFees = res.data.sumTotalBondingFeeByCash;
                this.sumTotalDiscount = res.data.sumTotalDiscountByCash;
                this.sumTotalBondingFees = res.data.SumTotalBondingFees;
                this.sumTotalDiscount = res.data.sumTotalDiscount;
                this.sumTotalBank = "";
                this.sumTotalDebt = "";
                this.sumTotalSupport = "";
              } else if (this.selectedPayment == "bank") {
                this.sumTotalBank = res.data.sumTotalBank;
                this.sumTotalBondingFees = res.data.sumTotalBondingFeeByBank;
                this.sumTotalDiscount = res.data.sumTotalDiscountByBank;
                this.sumTotalBondingFees = res.data.SumTotalBondingFees;
                this.sumTotalDiscount = res.data.sumTotalDiscount;
                this.sumTotalCash = "";
                this.sumTotalDebt = "";
                this.sumTotalSupport = "";
              } else if (this.selectedPayment == "debt") {
                this.sumTotalDebt = res.data.sumTotalDebt;
                this.sumTotalBondingFees = res.data.sumTotalBondingFeeByDebt;
                this.sumTotalDiscount = res.data.sumTotalDiscountByDebt;
                this.sumTotalBondingFees = res.data.SumTotalBondingFees;
                this.sumTotalDiscount = res.data.sumTotalDiscount;
                this.sumTotalBank = "";
                this.sumTotalCash = "";
                this.sumTotalSupport = "";
              } else if (this.selectedPayment == "support") {
                this.sumTotalSupport = res.data.sumTotalSupport;
                this.sumTotalBondingFees = res.data.sumTotalBondingFeeBySupport;
                this.sumTotalDiscount = res.data.sumTotalDiscountBySupport;
                this.sumTotalBondingFees = res.data.SumTotalBondingFees;
                this.sumTotalDiscount = res.data.sumTotalDiscount;
                this.sumTotalBank = "";
                this.sumTotalCash = "";
                this.sumTotalDebt = "";
              }
              this.start_menu = false;
              this.end_menu = false;
              this.TableLoading = false;
              this.listUser();
            }, 100);
          })
          .catch(() => {
            this.TableLoading = false;
            this.end_menu = false;
            this.start_menu = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    listBranch() {
      this.$axios
          .get("list-branches")
          .then((res) => {
            setTimeout(() => {
              this.branches = res.data.branches;
              this.branches.unshift(this.defaultBranchValue);
              this.TableLoading = false;
              this.listUser();
            }, 100);
          })
          .catch(() => {
            this.TableLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    listUser() {
      this.$axios
          .get("filter-users", {
            params: {
              type: this.type,
              branchId: this.selectedBranch,
            },
          })
          .then((res) => {
            setTimeout(() => {
              this.users = res.data.users;
              this.TableLoading = false;
            }, 100);
          })
          .catch(() => {
            this.TableLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    viewInvoice(value) {
      let route = this.$router.resolve({name: 'InvoiceDetail', params: {id: value}});
      window.open(route.href, '_blank');
    },
    //Confrim Payment Type
    paymentType(value) {
      if (value == "debt") return "ຕິດໜີ້";
      else if (value == "cash") return "ເງິນສົດ";
      else if (value == "bank") return "ທະນາຄານ";
      else if (value == "support") return "ສະໜັບສະໝູນ";
      else return;
    },
    getPaymentTypeColor(value) {
      if (value == "bank") return "success--text";
      else if (value == "cash") return "primary--text";
      else if (value == "debt") return "error--text";
      else if (value == "support") return "purple--text";
    },
    saleType(sale, price) {
      if (sale == "sale") return Number(price).toLocaleString();
      else if (sale == "free") return "Free";
    },
    getFreeStyle(sale) {
      if (sale == "free") return "primary-color";
    },
    totalPay(b, total, discount) {
      return Number(b + total - discount).toLocaleString();
    },
  },
  computed: {
    profile() {
     return JSON.parse(window.localStorage.getItem("Branch"));
    },
  },
  watch: {
    selectedBranch: function () {
      this.selectedUser = "all";
      this.pagination.current_page = '';
      this.fetchDeliveryInvoice();
    },
    selectedPayment: function () {
      this.pagination.current_page = '';
      this.fetchBranchInvoice();
    },
    start_date: function () {
      this.pagination.current_page = '';
      this.fetchBranchInvoice();
    },
    end_date: function () {
      this.pagination.current_page = '';
      this.fetchBranchInvoice();
    },
  },
  created() {
    this.listUser();
    this.listBranch();
    this.fetchBranchInvoice();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}

.invoice {
  font-size: 16px;
  color: #000000;
}

.primary-color {
  color: #00c1d2;
}
</style>
